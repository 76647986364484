nav.navbar{
    padding: 18px 0;
    position:fixed;
    width: 100%;
    top:0;
    z-index:9999;
    transition: 0.32s ease-in-out;
}
nav.navbar.scrolled{
    padding:0px 0;
    background-color:#121212;
}
nav.navbar a.navbar-brand{
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link{
    font-weight: 400;
    color:#fff!important;
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 18px;
    opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active{
    opacity:1;
}
span.navbar-text{
    display: flex;
    align-items: center;
}
.social-icon{
    display: inline-block;
    margin-left:14px
}
.social-icon a {
    width: 42px;
    height: 42px;
    background:rgba(189, 73, 247, 0.853);
    display:inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border:1px solid rgba(255, 255,255,0.5)
}
.social-icon a:hover::before{
    transform: scale(1);
}
.social-icon a img{
    width: 50%;
    z-index: 1;
    transition: 0.3s ease-in-out;
}
.social-icon a:hover img{
    filter:brightness(0) saturate(100%) invert(0%) sepia(7%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button{
    font-weight:700;
    color: #fff;
    border: 1px solid purple;
    padding: 18px 34px;
    position: relative;
    font-size: 18px;
    border-radius: 12px;
    margin-left: 18px;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}
.navbar-text button span{
    z-index: 1;
}
.navbar-text button:hover{
    color:#121212;
}
.navbar-text button:hover::before{
    content: "";
    width: 100%;
    height: 100%;
    position:absolute;
}
