@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
.text-sphere{
    position: relative;
    top:0;
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.tagcloud{
    display: inline-block;
    top:0;
    left:0;
    font-family:sans-serif,inherit;
    font-weight:400;
    letter-spacing: 0.0625rem;
    font-size: 1.3rem;
}
.tagcloud--item{
    color: aqua;
    text-transform: uppercase;
}
.tagcloud--item:hover{
    color:purple;
    transform: scale(1.2);
}
.skills{
    padding: 0 0 50px 0;
    position: relative;
}
.skills h2{
font-size:45px;
font-weight:700;
color: rgb(0, 72, 255);
justify-content: center;
align-items: center;
}
.skills p{
    font-size:19px;
    width:600px;
    font-weight:700;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin:14px 0 ;
}
/* .skills .background-image-left{
    top:28%;
    position:absolute;
    bottom: 0;
    width: 40%;
    right:0;
    z-index: -4;
} */